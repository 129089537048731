var $ = jQuery.noConflict();

(function( $ ) {
	BS = {
		GLOBAL: {
			init: function() {
				$('a[href*="#"]:not([href="#"])').click(function() {
					if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
						var target = $(this.hash);
						target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
						if (target.length) {
							var $el = $('#sticky .nav');
							$el.parent().removeClass('active');
							$(this).parent().addClass('active');

							$('html, body').animate({
								scrollTop: target.offset().top - 120
							}, 1000);
							return false;
						}
					}
				});

				// Add arrow icons
				$('.subscribe-form ._submit').html('<img class="submit-button" src="https://blacksmith.co.nz/wp-content/themes/bs/img/arrow-theme5.png">');

				$('.content-sections-active-campaign-form ._submit').html('Submit <svg class="nav-arrow"><use xlink:href="#arrow-theme0"></use></svg>');

				$('.nav-btn').click(function(e) {
					e.preventDefault();
					$('.header-search-mobile').hide();
					$(this).toggleClass('open');
					$('#navbar').slideToggle(700);
				});

				$('.search-toggle').click(function(e) {
					var headerSearch = $('.header-search');
					var headerSearchMobile = $('.header-search-mobile');
					var pageWidth = $(window).width();
					if(pageWidth >= 1085) {
						if (! headerSearch.hasClass('open') || ! $('.search-field', headerSearch).val()) {
							e.preventDefault();
							headerSearch.toggleClass('open');
						} else {
							headerSearch.find('.search-form').submit();
						}
					} else {
						$('.navbar-toggle').removeClass('open');
						$('#navbar').hide();
						if (! headerSearchMobile.hasClass('open') || ! $('.search-field', headerSearchMobile).val()) {
							e.preventDefault();
							headerSearchMobile.toggleClass('open').slideToggle(300);
						} else {
							headerSearchMobile.find('.search-form').submit();
						}
					}
				});

				$('#load-more a').on('click', function(e) {
					e.preventDefault();

					var postType = $(this).attr('data-type');
					var paged = parseInt($(this).attr('data-paged'));

					BS.GLOBAL.fetchPosts(postType, paged);
				});

				$('#load-more-events a').on('click', function(e) {
					e.preventDefault();

					var postType = $(this).attr('data-type');
					var paged = parseInt($(this).attr('data-paged'));

					BS.GLOBAL.fetchEvents(paged);
				});

				$('#load-more-search a').on('click', function(e) {
					e.preventDefault();

					var keyword = $(this).attr('data-keyword');
					var paged = parseInt($(this).attr('data-paged'));

					BS.GLOBAL.searchPosts(keyword, paged);
				});

				$('.toggle-bio a').on('click', function(e) {
					e.preventDefault();

					if($(this).text() == 'Show Bio') {
						$(this).text('Hide Bio').parent().addClass('open');
					} else {
						$(this).text('Show Bio').parent().removeClass('open');
					}

					$(this).parent().prev().find('p').slideToggle(800, 'linear');
				});

				if ($('#hero-video').length) {
					videojs("hero-video").ready(function() {
						var myplayer = this;

						myplayer.on("waiting", function(){
							$('#header-player .black-overlay').hide();
						});
					});
				}

				if ($('#footer-video').length) {
					videojs("footer-video").ready(function() {
						var myplayer = this;

						myplayer.on("waiting", function(){
							$('#footer-player .black-overlay').hide();
						});
					});
				}

				var fadeIn = ['tile', '_submit', 'dots', 'hero-banner-caption', 'contact-form', 'contact-item', 'video-player', 'block-organisations', 'block-individuals'];

				$.each(fadeIn, function(i, item) {
					if ($('.' + item).length) {
						inView('.' + item)
						.on('enter', function(el) {
							$(el).addClass('fadeIn');
						});
					}
				});

			},

			fetchPosts: function(post_type, paged) {

				var data = {
					'type': post_type,
					'numposts': 9,
					'paged': paged,
					filters: BS.BLOG.filters
				};

				$.ajax({
					type: "GET",
					url: "/wp-content/themes/bs/fetch-posts.php",
					data: data,
					dataType: "json",
					error: function(err) {
						console.log('error:', err);
					},
					success: function(response){
						var content = ''; console.log('Works');

						if(response[0].posts_found) {
							content +=  '<div class="row">';

							$.each(response[0].posts, function( index, value ) {
								content +=  '<div class="col-sm-6 col-md-4 tile-container">' +
												'<a class="tile" href="' + value['permalink'] + '"">' +
													'<div class="tile-img">' +
														'<div class="tile-img-container">' +
															'<div class="tile-img-cover" style="background-image: url('+ value['thumbnail'] +')"></div>' +
														'</div>' +
														'<div class="tile-img-overlay"></div>' +
													'</div>' +
													'<div class="tile-label">' +
														'<div class="tile-label-title text-center vAlign"><span>' + value['post_title'] + '</span></div>' +
														'<div class="tile-label-date text-center vAlign"><span>' + value['dateFormatted'] + '</span></div>' +
														'<div class="tile-label-overlay"></div>' +
													'</div>' +
												'</a>' +
											'</div>';

								if (index != 0 && index % 3 === 2) {
									content += '</div>';
									content +=  '<div class="row">';
								}
							});

							content += '</div>';
						} else {
							content = '<div class="row"><div class="col-xs-12"><h3 class="text-center">Nothing found</h3></div></div>';
						}

						$('#tiles-blocks .tiles').append(content);

						if (content) {
		                    inView('.tile')
		    			    .on('enter', function(el) {
		    					$(el).addClass('fadeIn');
		    				});
		                }

						if( response[0].more === true ) {
							$('#load-more').show();
							$('#load-more a').attr('data-type', post_type).attr('data-paged', paged + 1 );
						} else {
							$('#load-more').hide();
							// $('#load-more a, #load-more svg').remove();
						}
					}
				});
			},

			fetchEvents: function(paged) {
				$.ajax({
					type: "GET",
					url: "/wp-content/themes/bs/fetch-events.php",
					data: {
						'numposts': 9,
						'paged': paged
					},
					dataType: "json",
					error: function(err) {
						console.log('error:', err.responseText);
					},
					success: function(response) {
						var content = '';

						if(response[0].posts_found) {
							content +=  '<div class="row">';

							$.each(response[0].posts, function( index, value ) {
								content +=  '<div class="col-sm-6 col-md-4 tile-container">' +
									'<a class="tile" href="' + value['permalink'] + '"">' +
									'<div class="tile-img">' +
									'<div class="tile-img-container">' +
									'<div class="tile-img-cover" style="background-image: url('+ value['logo_url'] +')"></div>' +
									'</div>' +
									'<div class="tile-img-overlay"></div>' +
									'</div>' +
									'<div class="tile-label">' +
									'<div class="tile-label-title text-center vAlign"><span>' + value['post_title'] + '</span></div>' +
									'<div class="tile-label-date text-center vAlign"><span>' + value['dateFormatted'] + '</span></div>' +
									'<div class="tile-label-overlay"></div>' +
									'</div>' +
									'</a>' +
									'</div>'

								if (index != 0 && index % 3 === 2) {
									content += '</div>';
									content +=  '<div class="row">';
								}
							});

							content += '</div>';
						} else {
							content = '<div class="row"><div class="col-xs-12"><h3 class="text-center">Nothing found</h3></div></div>';
						}

						$('#tiles-blocks .tiles').append(content);

						if (content) {
		                    inView('.tile')
		    			    .on('enter', function(el) {
		    					$(el).addClass('fadeIn');
		    				});
		                }

						if( response[0].more === true ) {
							$('#load-more-events a').attr('data-paged', paged + 1 ).show();
						} else {
							$('#load-more-events a, #load-more-events svg').remove();
						}
					}
				});
			},

			searchPosts: function(keyword, paged) {

				var data = {
					'numposts': 4,
					'paged': paged,
					'keyword': keyword
				};

				$.ajax({
					type: "GET",
					url: "/wp-content/themes/bs/fetch-posts.php",
					data: data,
					dataType: "json",
					error: function(err) {
						console.log('error:', err);
					},
					success: function(response){
						var content = '';

						if(response[0].posts_found) {
							$.each(response[0].posts, function( index, value ) {
								content +=  '<article id="post-' + value['ID'] + '" class="search-result col-xs-12 col-sm-8 col-sm-offset-2">' +
												'<h3 class="result-tile">' +
													'<a href="' + value['permalink'] + '" class="theme4" rel="bookmark">' + value['post_title'] + '</a>' +
												'</h3>' +
												'<div class="result-excerpt">' + value['post_excerpt'] + '</div>' +
												'<a href="' + value['permalink'] + '" class="result-link theme4">' + value['permalink'] + '</a>' +
											'</article>';
							});
						} else {
							content = '<h2 class="entry-title">Sorry no results found.</h2>';
						}

						$('#search-results-container').append(content);

						if( response[0].more === true ) {
							$('#load-more-search').show();
							$('#load-more-search a').attr('data-keyword', keyword).attr('data-paged', paged + 1 );
						} else {
							$('#load-more-search').hide();
							// $('#load-more a, #load-more svg').remove();
						}
					}
				});
			}
		},

		HOME: {
			init: function() {

				$('.subscribe-form form').submit(function() {
					dataLayer.push({'event': 'email-signup'});
				});

				var pageWidth = $(document).width();
				$('.homepage-event-slider').owlCarousel({
					singleItem: true,
					navigation: true,
					pagination: true,
					autoHeight: true,
					addClassActive: true,
					navigationText: [
						'<svg><use xlink:href="#arrow-theme0" /></svg>',
						'<svg><use xlink:href="#arrow-theme0" /></svg>'
					],
					afterAction: afterAction
				});

				function afterAction(){
					var $el = $(this.owl.baseElement.context);
					if( this.owl.currentItem === 0 ) {
						$('.owl-prev', $el).removeClass('middle-nav');
						$('.owl-next', $el).removeClass('last-nav');
					} else if( this.owl.currentItem === this.owl.owlItems.length - 1 ) {
						$('.owl-prev', $el).addClass('middle-nav');
						$('.owl-next', $el).addClass('last-nav');
					} else if( this.owl.currentItem !== 0 && this.owl.currentItem < this.owl.owlItems.length ) {
						$('.owl-prev', $el).addClass('middle-nav');
						$('.owl-next', $el).removeClass('last-nav');
					}
				}

				var owlOptions = {
					singleItem: true,
					navigation: true,
					navigationText: [
						'<svg><use xlink:href="#arrow-theme1" /></svg>',
						'<svg><use xlink:href="#arrow-theme1" /></svg>'
					],
					pagination: true,
					slideSpeed: 1000,
					paginationSpeed: 800,
					afterAction: afterAction
				};

				if(pageWidth < 768) {
					$("#whats-new").owlCarousel(owlOptions);
				}

				function resizedw() {
					$("#whats-new").owlCarousel();
					var owl = $("#whats-new").data('owlCarousel');

					if($(document).width() < 768) {
						owl.reinit(owlOptions);
					} else {
						owl.destroy();
					}
				}

				var doit;
				window.onresize = function() {
					clearTimeout(doit);
					doit = setTimeout(resizedw, 100);
				};

                if ($('.wave-container').length) {
                    inView('.wave-container')
    			    .on('enter', function(el) {
    					$(el).addClass('wave-container-visible');
    				});
                }
			}
		},

		DETAILS: {
			init: function () {

					/**
					 * jQuery function to prevent default anchor event and take the href * and the title to make a share popup
					 *
					 * @param  {[object]} e           [Mouse event]
					 * @param  {[integer]} intWidth   [Popup width defalut 500]
					 * @param  {[integer]} intHeight  [Popup height defalut 400]
					 * @param  {[boolean]} blnResize  [Is popup resizeabel default true]
					 */
					$.fn.customerPopup = function (e, intWidth, intHeight, blnResize) {

						// Prevent default anchor event
						e.preventDefault();

						// Set values for window
						intWidth = intWidth || '500';
						intHeight = intHeight || '400';
						strResize = (blnResize ? 'yes' : 'no');

						// Set title and open popup with focus on it
						var strTitle = ((typeof this.attr('title') !== 'undefined') ? this.attr('title') : 'Social Share'),
							strParam = 'width=' + intWidth + ',height=' + intHeight + ',resizable=' + strResize,
							objWindow = window.open(this.attr('href'), strTitle, strParam).focus();
					};

					/* ================================================== */

					$(document).ready(function ($) {
						$('.customer.share').on("click", function(e) {
							$(this).customerPopup(e);
						});
					});

			},

			scroller: function() {

				$('#sticky').stick_in_parent({
					offset_top: 140
				});

				inView.offset(100);
				inView('.content-sections-section')
			    .on('enter', function(el) {
					var id = $(el).attr('id');
					if (id) {
						$('.nav .' + id).addClass('active');
					}
				})
			    .on('exit', function(el) {
					var id = $(el).attr('id');
					if (id) {
						$('.nav .' + id).removeClass('active');
					}
				});
			}
		},

		CAREERS: {
			scroller: function () {

				$('.toggle-job-description a').on('click', function(e) {
					e.preventDefault();

					if (!event.target.dataset.jobid) {
						return;
					}

					if($(this).text() == 'See full description') {
						$(this).text('Hide full description').parent().addClass('open');
					} else {
						$(this).text('See full description').parent().removeClass('open');
					}

					$('#' + event.target.dataset.jobid).slideToggle();

				});


				$('#sticky').stick_in_parent({
					offset_top: 140
				});

				inView.offset(100);
				inView('#overview')
					.on('enter', function(el) {
						$('#overview-sticky').addClass('active');
					})
					.on('exit', function(el) {
						$('#overview-sticky').removeClass('active');
					});
				inView('#hiring')
					.on('enter', function(el) {
						$('#hiring-sticky').addClass('active');
					})
					.on('exit', function(el) {
						$('#hiring-sticky').removeClass('active');
					});
			}
		},

		EVENTS: {
			init: function () {

			},

			scroller: function () {

				$('#sticky').stick_in_parent({
					offset_top: 140
				});

				inView.offset(100);
				inView('#event-purchase')
					.on('enter', function(el) {
						$('#event-purchase-sticky').addClass('active');
					})
					.on('exit', function(el) {
						$('#event-purchase-sticky').removeClass('active');
					});
				inView('#event-overview')
					.on('enter', function(el) {
						$('#event-overview-sticky').addClass('active');
					})
					.on('exit', function(el) {
						$('#event-overview-sticky').removeClass('active');
					});

			}
		},

		CONTACT: {
            init: function() {
                $('.contact-form ._submit').html('<svg class="nav-arrow"><use xlink:href="#arrow-theme0"></use></svg> Submit');
				$('.contact-form form').submit(function() {
					dataLayer.push({'event': 'contact-us'});
				});
            },
			map: function() {
				var el = $('#contact-map');

				if (el) {
					var lat = el.data('lat'),
						lng = el.data('lng'),
						loc = {
  						  lat: +lat,
  						  lng: +lng
  					  };

			        map = new google.maps.Map(document.getElementById('contact-map'), {
						zoom: 15,
						center: loc
			        });

			        var marker = new google.maps.Marker({
						position: loc,
						icon: el.data('marker'),
						map: map
			        });

					var infowindow = new google.maps.InfoWindow({
						content: el.data('address')
					});

					marker.addListener('click', function() {
						infowindow.open(map, marker);
					});
				}
			},
			reload: function() {
				setTimeout(function() {
					google.maps.event.trigger(map, 'resize');
				}, 100);
			}
		},

		BLOG: {

			filters: [],

			init: function() {
				$('#toggle-filter').click(function(e) {
					e.preventDefault();
					$(this).parent().prev().slideToggle();

					if($(this).text() == 'Show filters') {
						$(this).text('Hide filters').parent().addClass('rotate-icon');
					} else {
						$(this).text('Show filters').parent().removeClass('rotate-icon');
					}
				});

				$('#blog-filters ul li a').click(function(e) {
					e.preventDefault();
					if($(this).hasClass('selected')) {
						$(this).removeClass('selected')
					} else {
						$(this).addClass('selected');
					}
				});

				$('#blog-filters a.blog-filter-category').click(function(event) {

					if (event.target.dataset.catOnly) {
						//Deselect every filter but this one
						$('#blog-filters ul li a').removeClass('selected');
						$(this).addClass('selected');
						//Reset the filters to the one just selected
						BS.BLOG.filters = [event.target.dataset.cat];
					} else {
						$('#blog-filters ul li a.only').removeClass('selected');
						//rebuild filters array
						BS.BLOG.filters = [];
						$('#blog-filters ul li a.selected').each(function(index) {
							BS.BLOG.filters.push(this.dataset.cat);
						});
					}

					$('#tiles-blocks .tiles').html('');

					BS.GLOBAL.fetchPosts('post', 1);
				});
			}
		},

		ARTICLES: {

			filters: [],

			init: function() {
				$('#toggle-filter').click(function(e) {
					e.preventDefault();
					$(this).parent().prev().slideToggle();

					if($(this).text() == 'Show filters') {
						$(this).text('Hide filters').parent().addClass('rotate-icon');
					} else {
						$(this).text('Show filters').parent().removeClass('rotate-icon');
					}
				});

				$('#blog-filters ul li a').click(function(e) {
					e.preventDefault();
					if($(this).hasClass('selected')) {
						$(this).removeClass('selected')
					} else {
						$(this).addClass('selected');
					}
				});

				$('#blog-filters a.blog-filter-category').click(function(event) {

					if (event.target.dataset.catOnly) {
						//Deselect every filter but this one
						$('#blog-filters ul li a').removeClass('selected');
						$(this).addClass('selected');
						//Reset the filters to the one just selected
						BS.ARTICLES.filters = [event.target.dataset.cat];
					} else {
						$('#blog-filters ul li a.only').removeClass('selected');
						//rebuild filters array
						BS.ARTICLES.filters = [];
						$('#blog-filters ul li a.selected').each(function(index) {
							BS.ARTICLES.filters.push(this.dataset.cat);
						});
					}

					$('#tiles-blocks .tiles').html('');

					BS.GLOBAL.fetchPosts('post', 1);
				});
			}
		}
	};

	UTIL = {
		exec: function( controller, action ) {
			var ns = BS,
			action = ( action === undefined ) ? "init" : action;

			if( controller !== "" && ns[controller] && typeof ns[controller][action] === "function" ) {
				ns[controller][action]();
			}
		},
		init: function() {
			var cont = document.getElementsByClassName( "container" ),
			controller,
			action;

			UTIL.exec( "GLOBAL" );

			for( var i = 0, length = cont.length; i < length; i++ ) {
				controller = cont[i].getAttribute( "data-controller" );
				action = cont[i].getAttribute( "data-action" );
				UTIL.exec( controller );
				UTIL.exec( controller, action );
			}
		}
	};

	UTIL.init();
})( jQuery );
